<template>
    <el-container class="view-menu-authority">
        <el-main style="padding: 0 20px 0 0">
            <div class="opts">
                <el-select v-model="authority" placeholder="请选择角色">
                    <el-option
                        v-for="item in authorities"
                        :label="item.description"
                        :value="item.name"
                        :key="item.name"
                    ></el-option>
                </el-select>
            </div>
            <div class="menu-tree" v-loading="loading || saving">
                <el-tree
                    ref="menuTree"
                    :data="menus"
                    show-checkbox
                    node-key="id"
                    :props="defaultProps"
                    @check="preview"
                >
                </el-tree>
            </div>
            <el-button
                type="primary"
                @click="save"
                style="margin-top: 15px; margin-left: 20px"
                :loading="saving"
                :disabled="loading"
            >
                保存
            </el-button>
        </el-main>
        <el-aside>
            <div class="title">预览</div>
            <el-menu
                :collapse="false"
                :background-color="$theme['menu-bg']"
                :text-color="$theme['menu-text-color']"
                :active-text-color="$theme['menu-text-color-active']"
                :unique-opened="true"
                :router="true"
                style="border-right: 1px solid #545c64"
                class="el-menu-vertical-demo"
            >
                <sys-menu v-for="item in previewMenus" :menu="item" :key="item.id" noRoute> </sys-menu>
            </el-menu>
        </el-aside>
    </el-container>
</template>
<script>
import SysMenu from '../components/SysMenu';
export default {
    components: { SysMenu },
    data() {
        return {
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            menus: [],
            authorities: [],
            authority: null,
            previewMenus: [],
            loading: false,
            saving: false
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true;
            this.$http
                .get('/menu/all', { category: this.category })
                .then(res => {
                    this.menus = res;
                    return this.$http.get('/authority/all');
                })
                .then(res => {
                    //this.authorities = res.filter(i => i.name === 'ROLE_ADMIN' || i.name === 'ROLE_OPERATOR');
                    this.authorities = res || [];
                    if (!this.authority && res[0]) {
                        this.authority = res[0].name;
                    }
                    return this.$http.get(`/menu/authority/${this.authority}/get`);
                })
                .then(res => {
                    this.loading = false;
                    let checkedKeys = [];
                    const isChecked = menu => {
                        if (res.includes(menu.id)) {
                            if (menu.children && menu.children.length) {
                                let fullChecked = false;
                                menu.children.forEach(i => {
                                    let c = isChecked(i);
                                    if (c) {
                                        checkedKeys.push(i.id);
                                    }
                                    fullChecked = fullChecked && c;
                                });
                                return fullChecked;
                            } else {
                                return true;
                            }
                        }
                        return false;
                    };
                    this.menus.forEach(i => {
                        if (isChecked(i)) {
                            checkedKeys.push(i.id);
                        }
                    });
                    this.$refs.menuTree.setCheckedKeys(checkedKeys);
                    this.$nextTick(() => {
                        this.preview();
                    });
                })
                .catch(e => {
                    this.loading = false;                    
                    this.$message.error(e.error || e.message);
                });
        },
        preview() {
            const checkedNodes = this.$refs.menuTree.getCheckedNodes(false, true);

            const checkedIds = checkedNodes.map(node => node.id);

            const filter = menus => {
                let filtered = menus
                    .filter(m => checkedIds.includes(m.id))
                    .map(i => {
                        return { ...i };
                    });
                filtered.forEach(m => {
                    if (m.children) {
                        m.children = [...filter(m.children)];
                    }
                });
                return filtered;
            };
            this.previewMenus = filter(this.menus);
        },
        save() {
            this.saving = true;
            this.$http
                .post(
                    `/menu/authority/${this.authority}/save`,
                    this.$refs.menuTree.getCheckedNodes(false, true).map(node => node.id),
                    { body: 'json' }
                )
                .then(res => {
                    this.saving = false;
                    this.$message.success('保存成功');
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error || '保存失败');
                });
        }
    },
    watch: {
        authority() {
            this.getData();
        }
    }
};
</script>
<style lang="less" scoped>
.title {
    line-height: 32px;
    color: #303133;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
}
.opts {
    margin-bottom: 10px;
    > * {
        margin-right: 10px;
    }
}
</style>
<style lang="less">
.view-menu-authority {
    padding: 20px;
    .menu-tree {
        border-radius: 4px;
        background: white;
        padding: 10px;
    }
    .el-tree-node__content {
        height: 40px !important;
    }
    .el-aside {
        width: 200px !important;
    }
}
</style>
